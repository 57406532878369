.col-45-noti {
  margin-left: 45px;
}
.table_noti_list {
  margin-top: 30px;
  background-color: #fff;
  margin-left: 45px;
  margin-right: 45px;
  height: "100vh";
}
.notilist_tableHeader {
  display: flex;
  font-size: 14px;
  font-weight: bold;
  justify-content: left;
  align-items: center;
}
.noti_name_ {
  width: 200px !important;
}
.detail_text {
  display: flex;
  align-items: center;
}
.noti_input input {
  height: 48px;
  border-radius: 12px !important;
}
@media (max-width: 768px) {

  .table_noti_list {
    width: 90%;
    margin: 30px auto;
  }
}