.viewgroup_header {
  margin-left: 45px;
}
.viewgroup_detail {
  padding: 30px;
  margin-left: 50px;
  margin-top: 50px;
  margin-right: 50px;
  margin-bottom: 50px;
  background-color: white;
}

.viewgroup_header .header_leftside {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.detail_title {
  color: #008237;
  font-size: 20px;
  font-weight: bold;
  margin-left: 10px;
}

.button_group {
  display: flex;
  flex: 0 1;
  justify-content: space-between;
  margin-bottom: 10px;
}
.button_group_top {
  display: flex;

  justify-content: flex-end;
}
.button_group_top .delete_button {
  background: red !important;
  color: #fff !important;
  border: 1px solid red !important;
  width: 90% !important;
  height: 48px !important;
  border-radius: 12px !important;
  margin-right: 10px !important;
  font-weight: bold !important;
  font-size: 16px !important;
}
.button_group .update_button {
  background: #008237 !important;
  color: #fff !important;
  border: 1px solid #008237 !important;
  width: 90% !important;
  height: 48px !important;
  border-radius: 12px !important;
  margin-right: 10px !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-right: 8px;
}
.button_group .update_button:hover,
.modal_update:hover {
  background-color: #42be5c !important;
}
.button_group_top .edit_button {
  background: #008237 !important;
  color: #fff !important;
  border: 1px solid #008237 !important;
  width: 90% !important;
  height: 48px !important;
  border-radius: 12px !important;
  margin-right: 10px !important;
  font-weight: bold !important;
  font-size: 16px !important;
}
.button_group .delete_button {
  background: red !important;
  color: #fff !important;
  border: 1px solid red !important;
  width: 90% !important;
  height: 48px !important;
  border-radius: 12px !important;
  margin-right: 10px !important;
  font-weight: bold !important;
  font-size: 13px !important;
}
.button_group {
  margin-right: -80px;
}
.button_group .edit_button {
  background: #008237 !important;
  color: #fff !important;
  height: 48px !important;
  width: 90% !important;
  border-radius: 12px !important;
  font-size: 16px !important;
}

.permission_field {
  background-color: #008237;
  display: inline-block;
  min-height: 20px;
  border-radius: 10px;
  color: #ffffff;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 5px;
  margin-top: 5px;
}

.table_group_list {
  margin-top: 30px;
  background-color: #fff;
  margin-left: 45px;
  margin-right: 45px;
}

.gp_footer {
  width: 100%;
  height: 90px;
  box-shadow: 0px -4px 8px rgb(0 0 0 / 5%);
  left: 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 15px;
  background-color: white;
  z-index: 2;
  margin-top: 40px;
  position: fixed;
  bottom: 0;
}

.gp_footer .cancel_button {
  background: #fff !important;
  color: #008237 !important;
  border: 1px solid #008237 !important;
  width: 100% !important;
  height: 48px !important;
  border-radius: 12px !important;
  margin-right: 50px !important;
  font-weight: bold !important;
  font-size: 13px !important;
}
.gp_footer .cancel_button:hover {
  color: #42be5c;

  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.3)) !important;
}
.gp_footer .delete_button {
  background: red !important;
  color: #fff !important;
  border: 1px solid red !important;
  width: 90% !important;
  height: 48px !important;
  border-radius: 12px !important;
  margin-right: 10px !important;
  font-weight: bold !important;
  font-size: 16px !important;
}

.gplist_add {
  height: 40px;
  background: #008237;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
}

.gpsearch_bar {
  position: absolute;
}

.gpsearch_bar input {
  border-radius: 12px;
  outline: none;
  width: 250px;
  height: 40px;
  border: 1px solid #d8d8d8;
  padding: 0px 10px;
}

.gpsearch_bar_form {
  position: relative;
  width: inherit;
}

.usersearch_icon {
  position: absolute;
  top: 10px;
  right: 5px;
}

.usersearch_bar .search.icon {
  color: #228a4c;
}

.user-place-list {
  height: 40px;
  width: 30%;
  border-radius: 15px 0px 0px 15px !important;
}

.user-place-list div.divider {
  margin-top: 5px;
}

.user-place-list-icon {
  float: right;
  margin-top: 7px;
  margin-right: -20px;
  color: "#008237";
}

.filter_circle {
  background: green;
  border-radius: 35px;
  width: 40px;
  height: 40px;
}

.filter_icon {
  color: white;
  margin: 12px;
}
.add-member-popup-content {
  width: 60% !important;
}

.member-popup-content {
  width: 30% !important;
}

.add_member_popup {
  min-height: 500px;
  padding: 15px;
}

.add_member_popup .title {
  margin-bottom: 15px;
  font-weight: 700;
  color: black;
}

.rm_member_popup {
  min-height: 150px;
  padding: 15px;
}
.rm_member_popup .title {
  margin-bottom: 15px;
  font-weight: 700;
}
.rm_member_popup .member_content {
  border: 1px solid #d8d8d8;
  border-radius: 12px;
  margin-top: 5px;
}
.rm_member_popup .header {
  align-items: center;
  justify-content: flex-start;
  padding: 15px 15px 0px 15px;
}

.member_name {
  font-weight: bold;
}
.member_id {
  color: #008237;
  font-weight: bold;
  font-size: 12px;
  padding-left: 15px;
  padding-bottom: 15px;
}

.member_role {
  background-color: #008237;
  display: inline-block;
  min-height: 20px;
  border-radius: 10px;
  color: #ffffff;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 5px;
}
.ui.selection.dropdown.gp_dropdown {
  min-width: 130px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}
.ui.selection.dropdown.gp_dropdown .icon-profile-list-dropdown {
  margin-top: 0px !important;
}
.ui.search.dropdown.gp_dropdown > .text {
  left: -3px !important;
  top: 2px !important;
}

@media only screen and (min-width: 800px) {
  .sort-gp-users-down-icon,
  .sort-gp-users-up-icon {
    /* margin-left: -20px !important; */
    margin-top: -5px;
    margin-left: 5px;
  }
  .sort-gp-users-up-icon {
    margin-top: 5px;
  }
}
