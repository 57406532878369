.login_header {
  background-color: #006329;
  height: 48vh;
  padding: 50px 0px 0px 50px;
}
.login_header_title {
  font-weight: bolder;
  color: white;
  font-size: 18px;
}
.circle_one {
  position: absolute;
  top: 0;
  right: 0;
}
.circle_two {
  position: absolute;
  top: 0;
  right: 0;
}
.circle_three {
  position: absolute;
  top: 35%;
  left: 0;
}
.circle_four {
  position: absolute;
  left: 25%;
}
.circle_five {
  position: absolute;
  top: 50%;
  left: 20%;
  z-index: 2;
}
.circle_six {
  position: absolute;
  top: 70%;
  left: 35%;
}
.login_type_form {
  width: 35%;
  height: 200px;
  /* height: 230px; */
  background-color: white;
  border-radius: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* z-index: 99999999; */
  padding: 40px 50px;
  display: flex;
  flex-direction: column;
}
.login_form {
  width: 35%;
  height: 450px;
  background-color: white;
  border-radius: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* z-index: 99999999; */
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.login_btn button {
  background-color: #008237;
  width: 100%;
  height: 48px;
  color: #fff;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  outline: none;
  font-weight: 'bold';
  font-family: "Poppins";
  font-size: 18px;
}
.login_btn button:hover {
  background-color: #42be5c !important;
}
.microsoft_login_btn {
  background-color: #ffffff;
  width: 100%;
  height: 48px;
  color: #008237;
  border: 1.8px solid #42be5c;
  border-radius: 12px;
  cursor: pointer;
  outline: #42be5c;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  display: flex;
}
.microsoft_login_btn:hover {
  background-color: #42be5c !important;
}
.password-icon_login {
  position: absolute;
  top: 8px;
  right: 5px;
  background-color: none;
}
.otp_next {
  background-color: #008237 !important;
  color: #ffffff !important;
  margin-top: 50px !important;
}
.otp_next:hover {
  background-color: #42be5c !important;
}
.password-div-login {
  margin: 0;
  width: 100%;
  flex: 1 0 auto;
  outline: 0;
  padding: 0px;
  background: #fff !important;
  border: 1px solid rgba(34, 36, 38, 0.15);

  color: rgba(0, 0, 0, 0.87);
  border-radius: 12px;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  box-shadow: none;
  height: 48px;
}
.password-div-login.error {
  border: 1px solid #e72d35 !important;
}
.user_new_pass-login {
  width: 93%;
}
.user_new_pass-login.error input {
  background-color: #fff0f0 !important;
}
.user_new_pass-login input {
  height: 38px !important;
  margin-top: 3px !important;
  margin-left: 1px !important;
  border: none !important;
}
.login_header .logo img {
  width: 150px;
  height: 90px;
  /* image-rendering: -webkit-optimize-contrast; */
  /* margin-left: -30px; */
  filter: brightness(0) invert(1);
}
.loginAlertBox {
  background-color: rgba(0, 0, 0, 0.35) !important;
  width: 100vw !important;
  height: 100vw !important;
  /* z-index: 999999999999 !important; */
}
.login_cancel_button {
  width: 100px;
  height: 48px;
  border: 1px solid #008237 !important;
  background: none !important;
  border-radius: 12px !important;
  color: #008237 !important;
  font-weight: bold !important;
  font-size: 13px !important;
}

@media (max-width: 1100px) {
  .login_type_form {
    top: 60%;
    min-width: 65%;
    /* height: 45%; */
    height: 35%;
    padding: 20px;
  }
  .login_form {
    top: 60%;
    min-width: 65%;
  }
  .logo_container {
    margin-top: -40px;
  }
  .login_header .logo {
    position: relative;
    top: -10%;
    z-index: 99999999999;
  }
  .login_header .login_header_title {
    font-size: 14px;
    z-index: 99999999999;
    position: relative;
  }
  .only img {
    width: 15px;
  }
}
