.user_audit_container {
  padding: 15px 25px;
}

.user_audit_header_leftside {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
}

.user_audit_header_title {
  font-size: 18px;
  color: #008237;
  font-weight: 700;
  margin-left: 10px;
}

.user_audit_list {
  margin-top: 35px;
  background-color: #fff;
  padding: 10px;
  border-radius: 12px;
}

.user_audit_btn {
  background-color: #008237!important;
  color: #fff!important;
  font-weight: 700!important;
  border-radius: 12px!important;
}

.user_audit_title {
  margin-bottom: 15px;
  font-weight: 900 !important;
  color: #000000;
}

.user_audit_detail_content {
  border: 1px solid #d8d8d8;
  border-radius: 12px;
}

.user_audit_detail_content .user_audit_header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 15px 0;
}

.user_audit_name {
  font-weight: 700;
}

.user_audit_dot {
  width: 3px;
  height: 3px;
  background-color: #000;
  border-radius: 50%;
  margin: 0 8px;
}

.user_audit_timestamp {
  color: #6f6f6f;
  font-weight: 700;
  font-size: 12px;
  padding-left: 15px;
  padding-bottom: 15px;
}

.user_audit_description {
  padding: 15px;
}

.user_audit_description_title {
  font-weight: 700;
  margin-bottom: 10px;
}

.user_audit_description_name {
  margin-bottom: 5px;
  font-size: 13px;
}

.user_audit_popup {
  min-height: 150px;
  padding: 15px;
}

.user_audit_description_label {
  color: #008237;
}

.user_details_popup-content {
  width: 40% !important;
}
