.place-list {
  width: 250px !important;
  margin-left: 20px;
}

.month-list {
  width: 120px !important;
  float: right;
  margin-right: 10px;
}

#dashboard-div {
  padding-top: 0px;
  height: 100%;
}

#all-dashboard {
  margin-top: 10px;

  height: 100%;
  display: block;
}

#left-dashboard,
#right-dashboard {
  display: inline-block;
}

#left-dashboard {
  width: 55%;
  height: 80%;
  margin-left: 20px;
}

#chart-type-list {
  width: 140px;
  margin-top: 10px;
  margin-left: 10px;
}

#left-dashboard-chart {
  background-color: #ffffff;
  width: 100%;
  height: 40%;
  border-radius: 10px;
  border: 1px solid #f2f2f2;
}

#left-dashboard-pie {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  width: 100%;
  /* height: 100%; */
  display: flex;
  justify-content: space-between;
  /* flex-direction: column; */
}
.left-dashboard-pie {
  /* display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; */
}

#left-dashboard-pie-left,
#left-dashboard-pie-center,
#left-dashboard-pie-right {
  /* display: inline-block; */
  background-color: #ffffff;
  width: 300px;
  width: 31.8%;
  height: 70vh;
  border-radius: 10px;
  border: 1px solid #f2f2f2;
  display: block;
}

#left-dashboard-pie-center,
#left-dashboard-pie-right {
  margin-left: 1.1%;
}

#right-dashboard {
  /* width: 41%; */
  /* height: 80%; */
  margin-left: 10px;
  margin-right: 20px;
  /* float: right; */
}

#right-dashboard-bottom-title {
  margin-top: 25px;
}

#right-dashboard-bottom-title > h5 {
  display: inline-block;
}

#right-dashboard-bottom-title > a {
  color: #008237;
}

#right-dashboard-bottom-view {
  /* float: right; */
}

#right-dashboard-bottom {
  width: 100%;
  height: 53vh;
  overflow-y: auto;
}

#right-dashboard-top {
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  height: 100px;
  border: 1px solid #f2f2f2;
}

#right-dashboard-top-left {
  padding: 22px;
  /* float: left; */
}

#right-dashboard-top-right {
  padding: 15px;
  font-size: 40px;
  /* float: right; */
}

#selected-place {
  margin-bottom: 5px;
  font-size: 20px;
}

#total-incidents {
  font-weight: 900;
}

#left-dashboard-chart-wrapper {
  width: 100%;
  height: 85%;
  padding: 10px;
}

.pie-title {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 10px !important;
}

.form-indi {
  background-color: #ffffff;
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  width: 100%;
  height: 150px;
  margin-bottom: 10px;
}

.form-indi-left {
  width: 60%;
  padding-left: 15px;
  padding-top: 15px;
  display: inline-block;
}

.form-indi-type {
  background-color: #2d8ce3;
  display: inline-block;
  min-height: 20px;
  border-radius: 10px;
  color: #ffffff;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
}

.form-indi-mainplace {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;
  margin-top: 15px !important;
}

.form-indi-subplace {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 600;
}

.form-indi-datetime {
  color: #7c7c7c;
  font-size: 13px;
}

.form-indi-right {
  float: right;
  display: inline-block;
  width: 40%;
  padding-right: 15px;
  padding-top: 15px;
}

.form-indi-id {
  float: right;
  color: #7c7c7c;
  font-weight: 600;
  font-size: 13px;
}

.form-indi-cat-div {
  width: 40px;
  height: 40px;
  border: 1.5px solid #fe6060;
  padding: 10px;
  background-color: #ffbbbb;
  color: #fe6060;
  float: right;
  border-radius: 10px;
  font-weight: 700;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-right: 3px;
}

.dot-name {
  font-size: 13px;
}

.pie-legend-div {
  height: 30%;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 20px;
  overflow-y: auto;
  text-align: left;
  margin-top: 20px;
  display: flex;
}

.pie-legend-div-no-data {
  height: 100%;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 20px;
  overflow-y: auto;
  text-align: left;
  margin-top: 20px;
}

.pie-legend-wrapper-left {
  width: 37%;
  float: left;
}

.pie-legend-wrapper-right {
  width: 52%;
  float: right;
}

.dashboard-dropdown {
  display: inline-block !important;
}

.dashboard-place-dropdown-icon {
  float: right;
  margin-top: 2px;
  margin-right: -20px;
  color: "#008237";
}
.dahsboard_chart_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  min-height: 400px;
  margin: 0px 25px;
  margin-top: 35px;
}
.dahsboard_chart_container .chart_container {
  width: 100%;
  /* min-height: 600px !important; */
  background-color: #fff;
  border-radius: 8px;
}
.chart_title {
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  margin: 10px 0px;
}
.value_container {
  display: flex;
  flex-direction: column;
}
.values_div {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  margin-left: 50px;
}
@media (max-width: 1300px) {
  #left-dashboard-pie {
    position: relative;
    top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #left-dashboard-pie-left,
  #left-dashboard-pie-center,
  #left-dashboard-pie-right {
    width: 100%;
    height: 100%;
  }
  #left-dashboard-pie-left,
  #left-dashboard-pie-center {
    margin-bottom: 50px;
  }
  #left-dashboard-pie-right {
    margin-bottom: 150px;
  }
  .dahsboard_chart_container {
    grid-template-columns: repeat(2, 1fr);
    height: 100px;
  }
  .pie_container {
    width: 500px !important;
    /* height: 330%; */
  }
}

@media (max-width: 768px) {
  .dahsboard_chart_container {
    grid-template-columns: repeat(1, 1fr);
  }
  .filter-list {
    height: auto;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .filter-list {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  .filter-list::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  .dashbord-width {
    overflow-x: hidden !important ;
  }
}
.google-visualization-tooltip {
  /* position: absolute !important;
  top: 100px !important;
  left: 100px !important; */
  pointer-events: none;
}
