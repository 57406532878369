.search_bar {
  position: absolute !important;
  right: 30px;
  top: 122px;
  background: #f8f8f8;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 1;
}
@media only screen and (max-width: 1081px) {
  .menu-text.active span {
    display: inline;
  }
  .menu-text span {
    display: none;
  }
}

/* @media only screen and (min-width: 717px) {
  .main-content-width {
    margin-top: 80px;
  }
} */

/* @media only screen and (max-width: 716px) {
  .search_bar {
    position: absolute;
    right: 10px;
    top: 140px;
  }
  .main-content-width {
    margin-top: 80px;
  }
  .incident_list_contianer {
    margin-top: 0px;
  }
}  */

/* .search_bar input {
/* .search_bar input {
  border-radius: 12px;
  outline: none;
  width: 250px;
  height: 40px;
  border: noned8;
  padding: 0px 10px;
} */
.search_bar_form input {
  border-radius: 12px;
  outline: none;
  height: 35px;
  /* border: 1px solid #d8d8d8; */
  border: none;
  padding-right: 15px;
  width: 90%;
  background-color: none;
}
.search_bar_form {
  position: relative;
  border-radius: 12px;
  outline: none;
  height: 38px;
  border: 1px solid #d8d8d8;
  padding: 0px 10px;
  background-color: #fff;

}
.search_icon {
  position: absolute;
  top: 10px;
  right: 5px;
}
.search_bar .search.icon {
  color: #228a4c;
}
.incident_list_contianer.desktop_incident_list_contianer {
  display: flex;
  padding: 10px;
}
.incident_list_contianer .sidebar {
  background-color: white;
  min-height: 100%;
  flex: 1;
  margin-right: 15px;
}
.incident_list.desktop_incident_list {
  flex: 9;
  margin-top: 55px;
}
.optional_draft {
  display: flex !important;
  justify-content: space-around;
}
.inlist_cases {
  height: 40px;
  color: #888888;
  font-weight: bold;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.inlist_cases.active {
  height: 40px;
  background-color: #008237;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: bold;
  border-radius: 10px;
}
.inlist_draft {
  width: 100%;
  display: flex;
  height: 40px;
  justify-content: flex-start;
  align-items: center;
}

.inlist_draft_item {
  /* border: 1px solid #979797; */
  border-left: 1px solid #979797;
  padding: 8px;
  font-weight: bold;
  color: #979797;
  font-size: 13px;
  cursor: pointer;
  width: fit-content;
  white-space: nowrap;
  border-top: 1px solid #979797;
  border-bottom: 1px solid #979797;
  border-right: 0;
}

.inlist_draft_item:hover {
  border-left: 1px solid #008237;
  padding: 8px;
  color: #008237;
  background-color: #e6f8e5;
  width: fit-content;
  font-weight: bold;
  font-size: 13px;
}
.inlist_draft_item:nth-last-child(1) {
  border-right: 1px solid #979797;
}
.inlist_draft_item.active {
  border-left: 1px solid #008237;
  border-right: 0;
  padding: 8px;
  font-weight: bold;
  color: #008237;
  font-size: 13px;
  cursor: pointer;
  width: fit-content;
  white-space: nowrap;
}
.inlist_draft_item.active:nth-last-child(1) {
  border-right: 1px solid #008237;
}
/* .inlist_expo {
  height: 40px;
  background: #008237;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
  padding: 0px;
} */
@media only screen and (max-width: 1200px) {
}
/* @media screen and (min-width: 1100px) {
  .table_incident_list {
    overflow-x: scroll;
    overflow-y: hidden;
    width: 1100px;
  }
} */

.inlist_expo:hover {
  background-color: #42be5c !important;
}
/* table_incident_list */
.table_incident_list {
  margin-top: 30px;
  background-color: #fff;
  width: calc(100vw - 270px);
  overflow-x: scroll;
  overflow-y: hidden;
  /* max-width: 1100px; */
  /* max-width: '50%'; */
  /* display: flex; */
}
.inlist_tableHeader {
  display: flex;
  font-size: 13px;
  font-weight: bold;
}
.inlist_tableHeader img {
  margin-left: 5px;
}
.inlist_tableHeader.no {
}
.inlist_tableHeader.category {
  /* width: 60px; */
}
.inlist_tableHeader.approval {
  /* width: 65px; */
}
.inlist_tableHeader.park {
  /* width: 85px; */
}
.inlist_tableHeader.zone {
  /* width: 85px; */
}
.inlist_tableHeader.type {
  /* width: 130px; */
}
.inlist_tableHeader.placement {
  /* width: 80px; */
  margin-right: 5px;
}
.inlist_tableHeader.follow_up {
  /* width: 45px; */
}
.inlist_tableHeader.date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 100px; */
}
.inlist_tableHeader.no {
  cursor: pointer;
}
.inlist_tableHeader.export {
  /* width: 30px; */
}

.inlist_tableRow {
  min-height: 80px;
  font-size: 12px;
  cursor: pointer;
  margin-bottom: 10px;
}
/* inlist sidebar */
.inlist_sidebar {
  padding: 15px 10px;
}
.inlist_sidebar .from_date {
  font-size: 12px;
  font-weight: bold;
}
.inlist_sidebar .input_container {
  margin-bottom: 25px;
}
.inlist_sidebar .sidebar_datePicker {
  position: relative;

  flex-direction: row;
  border: 1px solid #d8d8d8;
  width: 100%;
  padding: 10px;
  border-radius: 12px;
  outline: none;
  font-size: 15px;
  height: 48px;
  letter-spacing: 1px;
  align-items: center;
}
.sidebar_mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: scroll;
}
.inlist_sidebar .input_title {
  font-weight: bold;
  font-size: 13px;
  margin-bottom: 10px;
}
.inlist_sidebar .sidebar_datePicker input {
  width: 70%;
  outline: none;
  border: none;
  font-size: 15px;
  letter-spacing: 1px;
  position: absolute;
  top: 14px;
}
.inlist_sidebar .sidebar_datePicker img {
  position: absolute;
  top: 15px;
  right: 8px;
}

.sidebar_Dropdown {
  position: relative;
  height: 48px;
  margin-bottom: 30px;
}
.sidebar_Dropdown .ui.selection.dropdown {
  border: 1px solid #d8d8d8;
  width: 100%;
  height: 50px;
  border-radius: 12px;
}

.sidebar_Dropdown .ui.search.dropdown > .text {
  margin-top: 5px;
}
.sidebar_Dropdown .ui.search.dropdown.active > input.search,
.sidebar_Dropdown .ui.search.dropdown.visible > input.search {
  margin-top: 5px;
}
.sidebar_Dropdown .ui.search.selection.dropdown > input.search {
  margin-top: 5px;
}
.sidebar_Dropdown img {
  position: absolute;
  top: 20px;
  right: 8px;
}

.incident-place-list {
  /* border-radius: 15px !important;
  height: 40px;
  width: 100%;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important; */
  border-radius: 15px !important;
  min-height: 40px;
  /* min-width: 200px !important; */
  max-width: 100%;
}

.incident-place-list div.divider {
  margin-top: 2px;
}

.incident-place-list input {
  height: 100%;
  width: 5% !important;
}
.test {
  /* padding-bottom: 10px; */
  margin-left: 20px;
}

.incident-place-list-icon {
  float: right;
  margin-top: 5px;
  margin-right: -20px;
}
.incident-place-list-icon-age {
  margin-top: -30px !important;
  margin-right: 0px !important;
}
.incident-list-dropdown {
  display: inline-block !important;
}
.incident-list-dropdown-age {
  min-width: 10% !important;
  display: inline-block !important;
}
.multiple_dropdown_list {
  /* min-height: 45px !important; */
  height: auto;
}
.multiple_dropdown_list a {
  margin-top: -3px !important;
}
.incidentAlertBoxContainer {
}
.react-confirm-alert-overlay .react-confirm-alert .incidentAlertBox {
  background-color: rgba(0, 0, 0, 0.35) !important;
  width: 100vw !important;
  height: 100vw !important;
  z-index: 999999999999 !important;
}
.downloadBtn {
  width: 100px;
  height: 48px;
  background: #008237 !important;
  border-radius: 12px !important;
  color: #fff !important;
  font-weight: bold !important;
  font-size: 16px !important;
  text-align: left !important;
}
.sort-down-icon,
.sort-up-icon {
  cursor: pointer;
}
@media only screen and (min-width: 800px) {
  .sort-down-icon,
  .sort-up-icon {
    margin-left: 0px !important;
  }
  .sort-up-icon {
    margin-top: 5px;
  }
  .inlist_tableHeader.date {
    justify-content: flex-start;
  }
}
.expo_container {
  /* position: relative;
  margin-top: -5.1%;
  right: -84%; */
  display: flex;
  /* width: 30%; */
  width: 250px !important;
}
.expo_container .inlist_expo {
  width: fit-content !important;
  height: 40px;
  background: #008237;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
  padding: 0px 12px;
}
.new_incident {
  width: 150px !important;
  /* margin-right: 10px; */
  height: 40px;
  background: #008237;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
  padding: 0px 8px;
}

.DatePicker_custom .react-datepicker-wrapper{
  width: 100%;
}
@media screen and (max-width: 1314px) {
  .expo_container {
    top: 0%;
    left: 0%;
    margin-top: 0%;
    display: block;
    position: relative;
    width: 100%;
  }
}

@media screen and (max-width: 992px) {
  .inlist_draft {
    margin: 20px 0px;
  }
  .inlist_draft_item {
    font-size: 11px;
  }
  .inlist_draft_item:hover {
    border: 1px solid #008237;
    padding: 8px;
    color: #008237;
    background-color: #e6f8e5;
    font-weight: bold;
    font-size: 11px;
  }
  .inlist_draft_item.active {
    border: 1px solid #008237;
    padding: 8px;
    color: #008237;
    background-color: #e6f8e5;
    font-weight: bold;
    font-size: 11px;
    width: fit-content;
  }
  .expo_container .inlist_expo {
    width: 100% !important;
  }
  .new_incident {
    width: 100% !important;
  }
  .test {
    /* padding-bottom: 10px; */
    margin-left: 0px;
  }
}

@media only screen and (max-width: 716px) {
  .search_bar {
    position: absolute;
    right: 10px;
    /* top: 250px !important; */
  }
  .inlist_tableHeader.date {
    justify-content: flex-start !important;
  }
  .incident_list_contianer {
    padding: 10px;
  }
  .inlist_draft {
    height: auto;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .inlist_draft {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  .inlist_draft::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  .mobile_incident_list {
    overflow-x: hidden !important;
  }
}
@media only screen and (max-width: 585px) {
  .menu-text.active span {
    display: none;
  }
  .search_bar {
    position: absolute !important;
    right: 10px !important;

    top: 135px !important;
  }
}

.paginat__fist_disable a:first-child {
  opacity: 0.6 !important;
  cursor: not-allowed !important;
  border-right: 1px solid rgba(34, 36, 38, 0.25) !important;
}
.paginat__fist_disable a:first-child:hover,
.paginat__last_disable a:last-child:hover {
  background-color: white !important;
}
.paginat__last_disable a:last-child {
  opacity: 0.6 !important;
  cursor: not-allowed !important;
}
.ui.fluid.dropdown.park_side_drop {
  width: 100% !important;
}

button.react-datepicker__close-icon::after {
  /* content: none !important; */
  background-color: transparent;
  font-size: 24px;
  color: #008237;
}
