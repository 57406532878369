.userprofile_container,
.userpermission_container,
.useraccount_container,
.userreset_container {
  padding-top: 50px;
  margin-bottom: 50px;
}

/* User Permission */
.upg_list_container {
  padding-top: 10px;
}
.active_sidebar_datapicker .react-datepicker-ignore-onclickoutside {
  pointer-events: none;
}

.upg_container {
  width: 100%;
  min-height: 80px;
  border: 1px solid #d8d8d8;
  border-radius: 12px;
  padding: 10px;
}

.upg_item_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.upg_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.upg_dot {
  background-color: #008237;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 15px;
}

.upg_add_btn {
  width: 100%;
  height: 1px;
  background-color: #d5d9cf;
  margin-bottom: 15px;
}

.add_upg {
  color: #008237;
  font-weight: bolder;
  font-size: 14px;
  cursor: pointer;
}

.group-list-dropdown a.ui.label {
  color: #ffffff;
  background-color: #008237;
}

.group-list-dropdown a.ui.label:hover {
  color: #ffffff;
  background-color: #008237;
}

.icon-group-list-dropdown {
  float: right;
  margin-top: 10px;
  margin-right: -20px;
  color: "#008237";
}

/* User Profile */

.profile-list-dropdown {
  width: 100%;
}

.icon-profile-list-dropdown {
  float: right;
  margin-top: 5px;
  margin-right: -20px;
  color: "#008237";
}

/* User Account */

.useraccount_container .sidebar_datePicker {
  position: relative;
  width: 100%;
}

.useraccount_container
  .sidebar_datePicker
  .react-datepicker__input-container
  input {
  border: 1px solid #d8d8d8;
  width: 100%;
  padding: 10px;
  border-radius: 12px;
  outline: none;
  font-size: 15px;
  height: 48px;
  letter-spacing: 1px;
}

.useraccount_container .sidebar_datePicker img {
  position: absolute;
  top: 15px;
  right: 8px;
}

.account_textarea {
  width: 100%;
  resize: none;
}

.useraccount_container .disabled {
  background-color: #efeff4;
  opacity: 1 !important;
}

.useraccount_container div.disabled input {
  background-color: #efeff4;
}

/* User Reset */
.password-div,
.sent-to-div {
  margin: 0;
  width: 100%;
  flex: 1 0 auto;
  outline: 0;
  padding: 0px;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  box-shadow: none;
}

.sent-to-div {
  height: 80px;
}

.child-sent-to-div {
  padding: 20px;
}

.child-sent-to-div-icon {
  background-color: #008237;
  display: inline-block;
  min-height: 20px;
  border-radius: 10px;
  color: #fff;
  padding: 2px 10px;
  font-size: 12px;
  float: right;
}

.child-sent-to-div-label1 {
  font-weight: 700;
}

.child-sent-to-div-label2 {
  color: #008237;
}

.password-icon {
  width: 40px;
  height: 40px;
  padding: 10px;
  cursor: pointer;
}

div.user_new_pass,
div.user_renew_pass {
  width: 93%;
}

div.user_new_pass input,
div.user_renew_pass input {
  border: none !important;
}

.user_deactivated_div {
  color: #008237;
  text-align: center;
  width: 100%;
  padding: 40px;
  background-color: rgb(230, 248, 229);
  font-size: 20px !important;
  font-weight: 900;
}

.user_deactivated_div p {
  padding-top: 30px;
}

.input_error_cls {
  border: 1px solid #e72d35 !important;
  color: #e72d35;
  background: #fff0f0 !important;
}

.input_error_cls input {
  background: #fff0f0 !important;
}

.input_error_text {
  color: #e72d35;
  margin-top: 15px;
}
