/* devanagari */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrJJbecnFHGPezSQ.woff2)
    format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8,
    U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrJJnecnFHGPezSQ.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

body {
  background-color: #f8f8f8;
  font-family: "Poppins" !important;
  overflow: hidden;
}

#company-logo {
  margin-right: 40px;
  cursor: pointer;
  /* width: 150px !important; */

  /* image-rendering: -webkit-optimize-contrast; */
}

#login-username {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
  width: 13px;
}

#login-label {
  color: #ffffff;
  background-color: #008237;
  cursor: auto;
  width: fit-content !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}
/* #login-label:hover {
  background-color: #42be5c;
} */
.green-text {
  color: #008237 !important;
}

#login-welcome {
  font-weight: 900;
}

#profile-icon:hover {
  color: #42be5c !important;
}
#noti-icon,
#profile-icon {
  cursor: pointer;
}
#noti-icon:hover,
#logout-icon:hover {
  color: #42be5c !important;
}
#logout-icon {
  margin-right: 10px;
  cursor: pointer;
  font-size: 16px;
}

#index-title {
  letter-spacing: 0.5px;
  font-weight: 500;
  padding-top: 2px;
}

#right-float {
  margin-left: auto;
  margin-right: 20px;
  color: #ffffff !important;
  stroke: #008237;
  stroke-width: 30px;
  font-size: 20px;
  padding: 10px;
}

#right-float svg {
  margin: 15px;
}

.active.item.menu-text {
  border-color: #008237 !important;
  border-bottom-width: 4px !important;
  color: #008237 !important;
  font-weight: 600 !important;
}
.active.item.menu-text:hover {
  color: #008237 !important;
}
.item.menu-text:hover {
  color: #42be5c !important  ;
}
.item.menu-text {
  color: #979797 !important;
  font-weight: 600 !important;
}
/* .item.menu-text.noti_settings {
  top: 50px;
  left: -150px;
  position: relative;
} */
.company-header {
  background-color: #ffffff;
  align-items: center;
  display: flex;
  padding-left: 20px;
}

.logo {
  width: 120px !important;
}

.ui.pointing.secondary.menu {
  font-size: 18px;
  border-bottom: 0px;
  padding-left: 20px;
  margin-top: 0px;
}
.side_noti {
  background-color: #fff;
  position: fixed;
  right: 0;
  top: 0;
  width: 380px;
  height: 100%;
  /* z-index: 9998; */
  z-index: 11;
  box-shadow: 0 0 15px #3333;
  padding: 10px;
}
.filter_noti .sidebar_datePicker {
  position: relative;
  margin: 20px 5px;
}
.filter_noti {
  background-color: #fff;
  position: fixed;
  right: 0;
  top: 0;
  width: 380px;
  height: 100%;
  z-index: 9998;
  box-shadow: 0 0 15px #3333;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.filter_noti .sidebar_datePicker .react-datepicker__input-container input {
  border: 1px solid #d8d8d8;
  width: 100%;
  padding: 10px;
  border-radius: 12px;
  outline: none;
  font-size: 15px;
  height: 48px;
  letter-spacing: 1px;
}
.filter_noti .sidebar_datePicker img {
  position: absolute;
  top: 15px;
  right: 8px;
}
.side_noti .noti_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d8d8d8;
  padding: 15px 10px;
  border-radius: 8px;
  margin-bottom: 8px;
  margin-right: 10px;
  cursor: pointer;
}
.side_noti .noti_item .dot_noti {
  width: 30px;
  height: 10px;
  border-radius: 100% !important;
  background-color: red !important;
  margin-right: 5px;
}
.noti_trash img:hover {
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.5)) !important;
}
.noti_text:hover {
  text-decoration: underline;
}

@media (max-width: 1100px) {
  .ui.table:not(.unstackable) tbody,
  .ui.table:not(.unstackable) tr,
  .ui.table:not(.unstackable) tr > td,
  .ui.table:not(.unstackable) tr > th {
    width: auto !important;
    display: block !important;
  }
  .ui.table:not(.unstackable) tr > td {
    background: 0 0;
    border: none !important;
    padding: 0.25em 0.75em !important;
    box-shadow: none !important;
  }
}

.main-content-width::-webkit-scrollbar:horizontal {
  display: none;
}
.main-content-width {
  /* margin-right: -16px; */

  overflow-x: hidden;
  overflow-y: auto;
  height: 80vh;
}
.nav_desktop {
  z-index: 2;
}
@media (max-width: 768px) {
  .main-content-width {
    /* margin-right: -16px; */

    overflow-x: auto;
    overflow-y: auto;
    height: 80vh;
  }
  /* .nav_desktop {
    display: none;
  }
  .nav_mobile {
    display: block;
  } */
}
.filter_info_model-overlay {
  z-index: 99999999999999 !important;
}
@media (max-width: 768px) {
  /* .nav_mobile {
    display: none;
  } */
  .stg {
    /* overflow: scroll; */
    padding-left: 150px;
  }
  .category_info_model-container-overlay
    .category_info_model-container-content {
    width: 100% !important;
    overflow: scroll;
  }
  #company-logo {
    width: 65px !important;
    margin-right:  15px !important;
  }
  .arrow_down_header_mobile::before {
    color: #fff;
  }
  .nav_desktop .ui.pointing.secondary.menu {
    font-size: 14px;
  }
}
