.user_detail_title {
  color: #008237;
  font-size: 20px;
  font-weight: 700;
  margin-left: 10px;
}

.user_detail_header .ud_header_leftside {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
}

.user_detail_header {
  display: flex;
  justify-content: space-between;
  margin: 0 20px;
}

.user_detail_form {
  display: flex;
  margin-top: 10px;
}

.user_detail_form .user_detail_sidebar {
  flex: 2 1;
  margin-left: 20px;
  height: 100vh;
}

.user_detail_sidebar .ud_option_container.active {
  background: #e6f8e5;
  color: #008237;
}

.user_detail_sidebar .ud_option_container,
.user_detail_sidebar .ud_option_container.active {
  display: flex;
  border-radius: 12px;
  height: 7vh;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 13px;
  padding: 0 10px;
  margin: 10px 0;
  cursor: pointer;
}
.user_detail_sidebar .ud_option_container {
  background: #fff;
  color: #606060;
}

.user_detail_form .ud_form.ud_add_flex {
  flex: 7 1;
}

.user_detail_form .ud_form {
  flex: 5 1;
  background-color: #fff;
  margin: 0 15px;
  display: flex;
  justify-content: center;
  position: relative;
  min-height: 100vh;
}

.user_detail_form .ud_form .ud_form_container.ud_remove_width {
  width: 600px;
}

.ud_incident_footer {
  position: fixed;
  width: 100%;
  height: 80px;
  box-shadow: 0px -4px 8px rgb(0 0 0 / 5%);
  left: 0;
  bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;

  background: white;
}

.ud_suspend_popup-content {
  width: 30% !important;
  padding: 15px !important;
  background-color: #fff;
  width: 400px;
  height: 400;
}

.ud_reactive_popup-content {
  width: 30% !important;
  padding: 15px !important;
}

.ud_suspend_popup,
.ud_reactive_popup {
  padding: 15px;
}
.ud_reactive_popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  background-color: #fff;
}
.ud_reactive_popup_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 4;
}
.ud_suspend_button {
  width: 140px;
  height: 48px;
  border: 1px solid #e72d35 !important;
  background: #e72d35 !important;
  border-radius: 12px !important;
  color: #fff !important;
  font-weight: 700 !important;
  font-size: 13px !important;
}

.suspend_cancel_btn,
.reactive_cancel_btn {
  width: 100px;
  height: 45px;
  border: 1px solid #008237 !important;
  background: none !important;
  border-radius: 12px !important;
  color: #008237 !important;
  font-weight: 700 !important;
  font-size: 13px !important;
  float: right;
  margin-bottom: 20px !important;
}

.suspend_submit_btn {
  width: 100px;
  height: 45px;
  border: 1px solid #e72d35 !important;
  background: #e72d35 !important;
  border-radius: 12px !important;
  color: #fff !important;
  font-weight: 700 !important;
  font-size: 13px !important;
  float: right;
  margin-bottom: 20px !important;
  margin-left: 10px !important;
}

.reactive_submit_btn {
  width: 200px;
  height: 48px;
  background: #008237 !important;
  border-radius: 12px !important;
  color: #fff !important;
  font-weight: bold !important;
  font-size: 16px !important;
  float: right;
  margin-left: 10px;
  text-align: center;
  vertical-align: middle;
  padding: 0px;
}

.ud_archive_button {
  width: 140px;
  height: 48px;
  border: 1px solid #e72d35 !important;
  background: #fff !important;
  border-radius: 12px !important;
  color: #e72d35 !important;
  font-weight: 700 !important;
  font-size: 13px !important;
}
.ud_archive_button:hover {
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.3)) !important;
}
.suspend_text_area {
  width: 100%;
  margin-bottom: 20px;
  resize: none;
  border-radius: 12px;
  padding: 10px !important;
  outline: none;
}
.suspend_text_area.error {
  border: 1px solid #e72d35 !important;
}
.errorReactiveTxt {
  color: #e72d35 !important;
}
.ud_cancel_button {
  width: 100px;
  height: 48px;
  border: 1px solid #008237 !important;
  background: #fff !important;
  border-radius: 12px !important;
  color: #008237 !important;
  font-weight: bold !important;
  font-size: 13px !important;
}

.ud_save_button {
  width: 100px;
  height: 48px;
  background: #008237 !important;
  border-radius: 12px !important;
  color: #fff !important;
  font-weight: bold !important;
  font-size: 16px !important;
}
.ud_save_button:hover {
  background-color: #42be5c !important;
}
.ud_cancel_button:hover {
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.3)) !important;
}
.ud_reset_email {
  width: 100px;
  height: 48px;
  background: #008237 !important;
  border-radius: 12px !important;
  color: #fff !important;
  font-weight: bold !important;
  font-size: 16px !important;
}

.ud_reactive_button {
  width: 200px;
  height: 48px;
  background: #008237 !important;
  border-radius: 12px !important;
  color: #fff !important;
  font-weight: bold !important;
  font-size: 16px !important;
}

.user_suspend_date_content {
  border: 1px solid #d8d8d8;
  border-radius: 12px;
  padding: 20px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.user_suspend_reason_content {
  border: 1px solid #d8d8d8;
  border-radius: 12px;
  padding: 20px;
  margin-top: 15px;
  margin-bottom: 40px;
}

.user_suspend_date_title,
.user_suspend_reason_title {
  font-weight: 700;
  margin-bottom: 10px;
}

.user_suspend_date_label {
  color: #008237;
}

.view_user_audit {
  color: #008237;
  font-weight: bold;
  font-size: 13px;
  text-decoration: underline;
  width: 150%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
}
.suspendAlertBox {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.35);
  width: 100%;
  height: 100%;
  z-index: 999999;
}
.input_title.error {
  color: 1px solid #e72d35 !important;
}
.ggerror {
  border: 1px solid #e72d35 !important;
}

@media only screen and (max-width: 768px) {
  .user_detail_form .ud_form.ud_add_flex {
    width: 100%;
  }
  .user_detail_form .ud_form .ud_form_container.ud_remove_width {
    width: 100%;
  }
  .ud_archive_button, .ud_cancel_button, .ud_save_button{
    width: 80px;
    font-size: 13px;
  }
  
}