.col-45 {
  margin-left: 45px;
}

.table_group_list {
  margin-top: 30px;
  background-color: #fff;
  margin-left: 45px;
  margin-right: 45px;
}

.gplist_tableHeader {
  display: flex;
  font-size: 14px;
  font-weight: bold;
  justify-content: left;
  align-items: center;
}

.gplist_add {
  height: 40px;
  background: #008237;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
}
.gp_name_ {
  width: 200px !important;
}
