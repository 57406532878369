.usersearch_bar {
  position: absolute;
  right: 20px;
  top: 122px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background: #f8f8f8;
  z-index: 2;
}
* {
  scroll-margin-top: 100px;
}
.usersearch_bar input {
  border-radius: 12px;
  outline: none;
  width: 250px;
  height: 40px;
  border: 1px solid #d8d8d8;
  padding: 0px 10px;
}

.usersearch_bar_form {
  position: relative;
}

.usersearch_icon {
  position: absolute;
  top: 10px;
  right: 5px;
}

.usersearch_bar .search.icon {
  color: #228a4c;
}

.user_list_contianer {
  display: flex;
  padding: 10px;
  margin-top: 25px;
}

.user_list_contianer .user_sidebar {
  background-color: white;
  min-height: 100%;
  flex: 1;
  margin-right: 15px;
}

.user_list {
  flex: 6;
}
.optional_draft {
  display: flex !important;
  justify-content: space-around;
}

.list_users {
  height: 40px;
  color: #888888;
  font-weight: bold;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.list_users.active {
  height: 40px;
  background-color: #008237;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: bold;
  border-radius: 10px;
}

.inlist_draft {
  width: 100%;
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
}

.inlist_draft_item {
  border: 1px solid #979797;
  padding: 10px;
  font-weight: bold;
  color: #979797;
  font-size: 14px;
  cursor: pointer;
}

.inlist_draft_item:nth-last-child(1) {
}
.inlist_draft_item.active {
  border: 1px solid #008237;
  padding: 10px;
  color: #008237;
  background-color: #e6f8e5;
  font-weight: bold;
  font-size: 14px;
}

.user_add {
  height: 40px;
  background: #008237 !important;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
}
.user_add:hover,
.user_add:hover > .add_new_user,
.user_add:hover > .user_add i.plus.icon {
  background-color: #42be5c !important;
}

/* table_incident_list */
.table_user_list {
  margin-top: 15px;
  background-color: #fff;
}

.userlist_tableHeader {
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  display: flex;
  cursor: pointer;
}

.userlist_tableHeader img {
  margin-left: 5px;
}

.userlist_tableHeader.no {
}

.userlist_tableHeader.category {
  width: 60px;
}

.userlist_tableHeader.approval {
  width: 55px;
}

.userlist_tableHeader.park {
  width: 85px;
}

.userlist_tableHeader.zone {
  width: 85px;
}

.userlist_tableHeader.type {
  width: 85px;
}

.userlist_tableHeader.placement {
  width: 80px;
  margin-right: 5px;
}

.userlist_tableHeader.follow_up {
  width: 45px;
}

.userlist_tableHeader.date {
  width: 50px;
}

.userlist_tableHeader.export {
  width: 30px;
}

.userlist_tableRow {
  min-height: 80px;
  font-size: 12px;
  cursor: pointer;
}

/* inlist sidebar */
.userlist_sidebar {
  padding: 15px 10px;
  background-color: #fff;
}

.userlist_sidebar .from_date {
  font-size: 12px;
  font-weight: bold;
}

.userlist_sidebar .input_container {
  margin-bottom: 25px;
}

.userlist_sidebar .sidebar_datePicker {
  position: relative;
  flex-direction: row;
  border: 1px solid #d8d8d8;
  width: 100%;
  padding: 10px;
  border-radius: 12px;
  outline: none;
  font-size: 15px;
  height: 48px;
  letter-spacing: 1px;
  align-items: center;
}

.userlist_sidebar .input_title {
  font-weight: bold;
  font-size: 13px;
  margin-bottom: 10px;
}

/* .userlist_sidebar .sidebar_datePicker .react-datepicker__input-container input {
  border: 1px solid #d8d8d8;
  width: 100%;
  padding: 10px;
  border-radius: 12px;
  outline: none;
  font-size: 15px;
  height: 48px;
  letter-spacing: 1px;
} */

.userlist_sidebar .sidebar_datePicker input {
  width: 70%;
  outline: none;
  border: none;
  font-size: 15px;
  letter-spacing: 1px;
  position: absolute;
  top: 14px;
}

.userlist_sidebar .sidebar_datePicker img {
  position: absolute;
  top: 15px;
  right: 8px;
}

.sidebar_Dropdown {
  position: relative;
  height: 48px;
  margin-bottom: 30px;
}
.sidebar_Dropdown .ui.selection.dropdown {
  border: 1px solid #d8d8d8;
  width: 100%;
  height: 50px;
  border-radius: 12px;
}

.sidebar_Dropdown .ui.search.dropdown > .text {
  margin-top: 5px;
}

.sidebar_Dropdown .ui.search.dropdown.active > input.search,
.sidebar_Dropdown .ui.search.dropdown.visible > input.search {
  margin-top: 5px;
}

.sidebar_Dropdown .ui.search.selection.dropdown > input.search {
  margin-top: 5px;
}

.sidebar_Dropdown img {
  position: absolute;
  top: 20px;
  right: 8px;
}

.usermg-place-list {
  border-radius: 15px !important;
  height: 48px;
  min-width: 200px !important;
  max-width: 100%;
}

.usermg-dept-list {
  border-radius: 15px !important;
  min-width: 200px !important;
  max-width: 100%;
  min-height: 48px;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}

.usermg-place-list div.divider {
  margin-top: 5px;
}

.usermg-place-list input {
  height: 100%;
}

.user-place-list-icon {
  float: right;
  margin-top: 7px;
  margin-right: -20px;
  color: "#008237";
}

.user-list-dropdown {
  display: inline-block !important;
}

.add_new_user {
  width: 100px;
  height: 40px;
  background: #008237 !important;
  border-radius: 12px !important;
  color: #fff !important;
  font-weight: bold !important;
  font-size: 16px !important;
  width: 5em !important;
}

.user_add i.plus.icon {
  background: transparent !important;
  height: 1.3em !important;
}
.mobile_filter_tabs button {
  width: 100px !important;
}
.mobile_filter_tabs.__incident_tabs button {
  min-width: 200px !important;
}
.mobile_filter_tabs.active button {
  background-color: #008237;
  color: #fff;
}
@media only screen and (max-width: 1081px) {
  .menu-text.active span {
    display: inline;
  }
  .menu-text span {
    display: none;
  }
}

@media only screen and (min-width: 717px) {
  .main-content-width {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 716px) {
  .usersearch_bar {
    position: absolute;
    right: 10px;
    /* top: 250px !important; */
  }
  .main-content-width {
    /* margin-top: 80px; */
  }
  .main-content-width.dashbord-width {
    /* height: 100vh; */
    height: 100%;
    margin-top: 0px;
  }
  .user_list_contianer {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 585px) {
  .menu-text.active span {
    display: none;
  }
  .usersearch_bar {
    position: absolute;
    right: 10px;
    top: 250px !important;
  }
}
@media only screen and (min-width: 800px) {
  .sort-users-down-icon,
  .sort-users-up-icon {
    /* margin-left: -20px !important; */
    margin-left: 5px;
  }
  .sort-users-up-icon {
    margin-top: 5px;
  }
}
