.incident_overview_container {
  padding-top: 30px !important;
}
h3 {
  font-size: 16px !important;
  font-weight: bold !important;
}
.casual_form_container,
.witness_form_container {
  padding: 20px;
}
.incident_overview_container h3 {
  font-size: 17px;
}
.dropdown_container .dropdown_title {
  font-size: 17px;
}
.ui.radio.checkbox.checkBox {
  margin: 5px 0px;
}
.ui.radio.checkbox.checkBox label:after {
  background-color: #008237 !important;
}
.ui.radio.checkbox.checkBox label {
  font-size: 13px;
}
.incident_overview_container .follow_required,
.category_title,
.damaged_title {
  font-weight: bolder;
  font-size: 16px;
}
.dropdown_container {
  margin-top: 20px;
}
.category_container {
  margin-top: 30px;
}
.dropdown_container .Dropdown {
  position: relative;
  height: 48px;
  margin-bottom: 30px;
}
.dropdown_container .Dropdown .ui.selection.dropdown {
  border: 1px solid #d8d8d8;
  width: 100%;
  height: 50px;
  border-radius: 12px;
}

.dropdown_container .Dropdown .ui.search.dropdown > .text {
  margin-top: 5px;
}
.dropdown_container .Dropdown .ui.search.dropdown.active > input.search,
.dropdown_container .Dropdown .ui.search.dropdown.visible > input.search {
  margin-top: 5px;
}
.dropdown_container .Dropdown .ui.search.selection.dropdown > input.search {
  margin-top: 5px;
}
.dropdown_container .Dropdown img {
  position: absolute;
  top: 20px;
  right: 8px;
}
.category_header {
  position: relative;
}
.category_header .category_image {
  position: absolute;
  top: 0;
  left: 75px;
}
.category_options {
  width: 100%;
  padding: 15px 0px;
  border: 1px solid #d8d8d8;
  border-radius: 12px;
  display: flex;
  justify-content: space-around;
  text-align: center;
}
.category_options .c_option {
  position: relative;
  cursor: pointer;
}
.category_options .c_circle {
  position: absolute;
  bottom: -8px;
  left: 20%;
  width: 24px !important;
  height: 24px;
  background-color: white;
  border: 1px solid #eae8e5;
  border-radius: 50%;
}

.datePicker {
  position: relative;
}
.input_title {
  font-weight: bold;
  font-size: 15px !important;
  margin-bottom: 10px;
  margin-top: 30px;
}
.react-datepicker__month-container {
  width: 100%;
}

.datePicker .react-datepicker__input-container input {
  border: 1px solid #d8d8d8;
  width: 100%;
  padding: 10px;
  border-radius: 12px;
  outline: none;
  font-size: 15px;
  height: 48px;
  letter-spacing: 1px;
}
.datePicker img {
  position: absolute;
  top: 15px;
  right: 8px;
}
.input_container {
  width: 95%;
}
.input_container2 {
  width: 100%;
}
.input_container_fluid {
  width: 100%;
}
.datePicker.timePicker .react-datepicker-popper {
  width: 100% !important;
}
.damaged_input input {
  height: 48px;
  border-radius: 12px !important;
}
.casual_input {
  width: 100%;
  height: 48px;
}
.casual_input.not_fluid {
  width: 90%;
}
.casual_input input {
  border-radius: 12px !important;
}
.remark_text_area {
  width: 100%;
  height: 224px;
  border: 1px solid #d8d8d8;
  border-radius: 12px;
  outline: none;
  padding: 10px;
  resize: none;
}
.remark_small_text_area,
.type_injury_remark_small_text_area {
  width: 100%;
  height: 80px;
  border: 1px solid #d8d8d8;
  border-radius: 12px;
  outline: none;
  padding: 10px;
  resize: none;
}
.type_injury_remark_small_text_area {
  height: 150px;
}
.underline_add_btn {
  width: 100%;
  height: 1px;
  background-color: #d5d9cf;
  margin-top: 15px;
  margin-bottom: 15px;
}
.add_button,
.add_casual,
.add_asset {
  color: #008237;
  font-weight: bolder;
  font-size: 14px;
  cursor: pointer;
}
.add_button {
  margin-bottom: 100px;
}
.add_casual,
.add_asset {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}
.add_casual {
  margin-left: 5px;
}
.add_asset {
  margin-left: 30px;
}
.casual_container,
.asset_container {
  width: 100%;
  min-height: 80px;
  border: 1px solid #d8d8d8;
  border-radius: 12px;
  padding: 10px;
}
.asset_container.error {
  background-color: #fff0f0;
  border: 1px solid #e72d35;
}
.casual_item_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.asset_item_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.asset_item {
  width: 100%;
  display: flex;
  justify-content: space-between !important;
  margin-bottom: 10px;
}
.action_container {
  display: flex;
  flex-direction: row;
}
.delete_btn {
  margin-right: 10px;
}
.casual_text,
.asset_text {
  word-break: break-all;
  font-size: 14px;
  font-weight: bolder;
  margin-right: 10px;
}
.casual_dot,
.asset_dot {
  background-color: #008237;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 15px;
}
.casual_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.casualty_modal {
  height: 100px;
}
.casualty_modal label {
  font-size: 20px !important;
}
.casualty_modal_input {
  height: 100px;
}
.asset_item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  height: 40px;
  cursor: pointer;
}
.add_asset .asset_file {
  width: 100%;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.casual_form_title,
.witness_form_title {
  color: #008237;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 35px;
}
.casual_dropdown_age_container {
  width: 10%;
  margin-right: 10px;
  position: relative;
  margin-bottom: 30px;
  height: 50px;
}

.casual_dropdown_gender_container {
  width: 25%;
  position: relative;
  margin-bottom: 30px;
  height: 50px;
}
.casual_divide_mid_container {
  position: relative;
  margin-bottom: 30px;
  height: 50px;
}
.dropdown_fluid {
  position: relative;
  margin-bottom: 50px;
  height: 50px;
}
.casual_divide_mid_container.add_wdith {
  width: 54%;
}
.input_title {
  font-size: 16px;
  font-weight: bold;
}
.casual_dropdown_age,
.casual_dropdown_gender {
  min-width: 100% !important;
  height: 50px !important;
  border-radius: 12px !important;
  display: flex !important;
  align-items: center !important;
}
.casual_divide_mid_container.spacer {
  width: 40%;
}
.casual_dropdown_age_container img,
.casual_dropdown_gender_container img,
.casual_divide_mid_container img,
.dropdown_fluid img {
  position: absolute;
  top: 52px;
  right: 8px;
}
.casual_footer,
.witness_footer {
  position: absolute;
  width: 100%;
  height: 80px;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.05);
  left: 0;
  bottom: 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.incident_footer {
  position: fixed;

  width: 100%;
  height: 80px;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.05);
  left: 0;
  bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
  z-index: 99;
  overflow-y: auto;
}
.incident_update_footer {
  width: 105%;
  height: 80px;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.05);

  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 15px;
  margin-left: -15px;
}
.incident_cancel_button {
  width: 100px;
  height: 48px;
  border: 1px solid #e72d35 !important;
  background: #e72d35 !important;
  border-radius: 12px !important;
  color: #fff !important;
  font-weight: bold !important;
  font-size: 13px !important;
}
.closed_incident_button {
  width: 99%;
  height: 48px;
  border: 1px solid #e72d35 !important;
  background: #e72d35 !important;
  border-radius: 12px !important;
  color: #fff !important;
  font-weight: bold !important;
  font-size: 13px !important;
  font-weight: bold !important;
}
.incident_save_button {
  width: 100px;
  height: 48px;
  border: 1px solid #e72d35 !important;
  background: none !important;
  border-radius: 12px !important;
  color: #e72d35 !important;
  font-weight: bold !important;
  font-size: 13px !important;
}
.casual_cancel_button,
.witness_cancel_button {
  width: 100px;
  height: 48px;
  border: 1px solid #008237 !important;
  background: none !important;
  border-radius: 12px !important;
  color: #008237 !important;
  font-weight: bold !important;
  font-size: 13px !important;
}
.reject_button {
  width: 100px;
  height: 48px;
  border: 1px solid #e72d35 !important;
  background: none !important;
  border-radius: 12px !important;
  color: #e72d35 !important;
  font-weight: bold !important;
  font-size: 13px !important;
}
.update_incident_cancel_button {
  width: 99%;
  height: 48px;
  border: 1px solid #008237 !important;
  background: none !important;
  border-radius: 12px !important;
  color: #008237 !important;
  font-weight: bold !important;
  font-size: 13px !important;
  margin-right: 10px !important;
}
.view_audit {
  color: #008237;
  font-weight: bold;
  font-size: 13px;
  text-decoration: underline;
  width: 150%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}
.casual_save_button,
.witness_save_button {
  width: 100px;
  height: 48px;
  background: #008237 !important;
  border-radius: 12px !important;
  color: #fff !important;
  font-weight: bold !important;
  font-size: 16px !important;
}
.casual_delete_button {
  width: 100px;
  height: 48px;
  background: #e72d35 !important;
  border-radius: 12px !important;
  color: #fff !important;
  font-weight: bold !important;
  font-size: 16px !important;
}
.gg_button {
  width: 100px;
  height: 48px;
  background: #fff !important;
  border-radius: 12px !important;
  color: #008237 !important;
  font-weight: bold !important;
  font-size: 16px !important;
  border: 1px solid #008237 !important;
}
.signature_Container {
  padding: 10px;
}
.signature_Container .signatureCanvas {
  border: 1px solid #d8d8d8;
  width: 100%;
  height: 300px;
  position: relative;
  border-radius: 12px;
}
.signature_reset_button {
  background: none;
  color: #008237;
  border-radius: 12px;
  height: 40px;
  width: 100px;
  border: 1px solid #008237;
  font-size: 13px;
  font-weight: bold;
  margin-right: 25px;
}
.signature_done_button,
.signature_clear_button {
  background: #008237;
  color: #fff;
  border-radius: 12px;
  height: 40px;
  width: 100px;
  border: none;
  font-size: 13px;
  font-weight: bold;
}
.signature_clear_button {
  position: absolute;
  top: 100px;
  right: 25px;
}
.incident_update_detail .in_update_security {
  background-color: #2d8ce3;
  color: white;
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 12px;
}
.incident_update_detail .in_update_code {
  font-size: 12px;
}
.incident_update_detail .in_update_title {
  font-weight: bold;
  font-size: 20px;
}
.incident_update_detail .in_update_area {
  width: fit-content;
  padding: 10px 12px;
  border: 1px solid #228a4c;
  color: #228a4c;
  margin-right: 5px;
  border-radius: 12px;
  font-size: 12px;
}
.incident_update_detail .in_update_timestamp {
  font-size: 11px;
  color: #606060;
  margin: 10px 0px;
}
.incident_update_detail .in_update_image_container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}
.incident_update_detail .in_update_placement {
  margin-bottom: 25px;
}
.incident_update_detail .in_update_placement .header {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 5px;
}
.incident_update_detail .in_update_placement div {
  font-size: 16px;
}
.incident_update_detail .in_update_detail {
  width: 100px;
  background-color: #008237;
  color: white;
  font-size: 13px;
  border-radius: 12px;
  padding: 8px 10px;
  text-align: center;
}
.incident_update_form {
  margin: 50px 0px;
  border: 1px solid #ceced2;
  border-radius: 12px;
}
.incident_update_form .in_update_header {
  background-color: #f8f8f8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.incident_update_form .in_update_header .title {
  font-size: 13px;
}
.in_update_header .btn {
  background-color: #008237;
  color: white;
  font-size: 13px;
  padding: 8px 10px;
  border-radius: 12px;
}
.incident_update_form .in_update_reason {
  padding: 10px;
}
.incident_update_form .in_update_reason .in_update_reason_item {
  border-bottom: 0.5px solid #ceced2;
  padding-bottom: 30px;
}
.incident_update_form .in_update_reason .title {
  font-size: 15px;
  font-weight: bold;
}
.incident_update_form .in_update_reason .submit_by {
  color: #808080;
  font-size: 13px;
}
.incident_update_form .in_update_reason .submit_by .name {
  color: #008237;
  /* margin: 0px 5px; */
  margin-left: 0px !important;
  font-weight: bold;
}
.in_update_form_container .title {
  font-size: 13px;
  font-weight: bold;
}
.in_update_form_container .upload_image {
  text-decoration: underline;
  color: #008237;
  display: flex;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 25px;
}
.popup-content.in_update_pop-content {
  width: 300px !important;
}
.popup-content.location_content-content {
  width: 100% !important;
  height: 100% !important;
}
.zonelistdropdown {
  position: absolute;
  top: 50px;
  background-color: white;
  border: 1px solid #d8d8d8;
  width: 100%;
  border-radius: 12px;
  left: 0;
  height: 200px;
  overflow: scroll;
  overflow-x: hidden;
  padding: 10px;
  z-index: 99;
  display: flex;
  flex-wrap: wrap;
}
.zonelistdropdown .zonelist_item {
  width: 135px;
  height: 120px;
  border: 1px solid #d8d8d8;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: bold;
  text-align: center;
  font-size: 13px;
  margin: 3px;
}
.zonelistdropdown .zonelist_item.active {
  width: 135px;
  height: 120px;
  border: 1px solid #d8d8d8;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: bold;
  text-align: center;
  font-size: 13px;
  background-color: #008237;
  color: white;
}
.zonelistdropdown .zonelist_item.active img {
  filter: invert(98%) sepia(100%) saturate(8%) hue-rotate(163deg)
    brightness(103%) contrast(103%) !important;
}

.marker {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  background-color: #000;
  border: 2px solid #fff;
  border-radius: 100%;
  user-select: none;
  transform: translate(-100%, -100%);
  margin: auto;
  text-align: center;
}

.notisearch_bar {
  position: relative;
}

.notisearch_bar input {
  border-radius: 12px;
  outline: none;
  width: 250px;
  height: 37px;
  border: none;
  outline: none;
  padding: 0px 10px;
}

.notisearch_bar_form {
  border-radius: 12px;
  position: relative;
  width: 100%;
  height: 40px;
  border: 1px solid #d8d8d8;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.notisearch_icon {
  position: absolute;
  top: 10px;
  right: 5px;
}

.notisearch_bar .search.icon {
  color: #228a4c;
}

.departbar {
  flex: 2;
  height: 100vh;
}
.departbar .option_container {
  display: flex;
  background: #fff;
  border-radius: 12px;
  height: 7vh;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 13px;
  color: #606060;
  padding: 0px 10px;
  margin: 10px 0px;
  cursor: pointer;
}
.departbar .option_container.active {
  display: flex;
  background: #e6f8e5;
  border-radius: 12px;
  height: 7vh;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 13px;
  color: #008237;
  padding: 0px 10px;
  margin: 10px 0px;
  cursor: pointer;
}

.user_container {
  margin: 10px;
}
.download_btn {
  margin-right: 10px;
  position: relative;
  display: inline;
}
#download-icon:hover {
  color: #fff;
}

#download-icon {
  color:#ceced2;
  cursor: pointer;
}

.ui.checkbox.checkBox label:after {
  background-color: #008237 !important;
  color: white !important;
}
@media only screen and (max-width: 768px) {

  .casual_dropdown_gender_container {
    width: 100% !important;
  }
  .popup-content {
    width: 100%;
  }
}