.detail_container {
  display: flex;
}
.case_detail {
  flex: 5;
}

.detail_header {
  display: flex;
  justify-content: space-between;
  margin: 0px 20px;
}
.detail_header .header_leftside {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
}
.detail_title_incident_form {
  color: #008237;
  font-size: 18px;
  font-weight: bold;
  /* margin-left: 10px; */
}
.detail_save_button {
  background-color: #008237 !important;
  color: white !important;
}
.detail_form {
  display: flex;
  margin-top: 10px;
}
.detail_form .sidebar {
  flex: 2;
  margin-left: 20px;
  height: 100vh;
}
.detail_form .form {
  flex: 5;
  background-color: white;
  margin: 0px 15px;
  display: flex;
  justify-content: center;
  position: relative;
  min-height: 130vh;
  padding-bottom: 100px;
}
.detail_form .form.add_flex {
  flex: 7;
}
.detail_form .form.remove_flex {
  flex: 5;
}
.detail_form .form .form_container.remove_width {
  width: 600px;
}
.detail_form .form .form_container.add_width {
  width: 100%;
}
.sidebar .title {
  font-size: 15px;
  font-weight: bold;
  margin: 10px 0;
}

.sidebar .option_container {
  display: flex;
  background: #fff;
  border-radius: 12px;
  height: 7vh;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 13px;
  color: #606060;
  padding: 0px 10px;
  margin: 10px 0px;
  cursor: pointer;
}
.sidebar .option_container.active {
  display: flex;
  background: #e6f8e5;
  border-radius: 12px;
  height: 7vh;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 13px;
  color: #008237;
  padding: 0px 10px;
  margin: 10px 0px;
  cursor: pointer;
}
.sidebar .option_container.error {
  border: 1px solid red;
}
.summary {
  margin-top: -15px;
  flex: 1.5;
  background-color: white;
  min-height: 100%;
  padding: 20px 20px 0px 20px;
}
.summary .summary_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.summary .summary_title:nth-of-type(1) {
  margin-right: 20px;
}
.summary .summary_title.active {
  color: #008237;
  font-weight: bold;
  font-size: 16px;
  text-decoration: underline;
}
.summary .summary_title {
  color: #888888;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}
.summary_stepper,
.summary_detail {
  display: flex;
  flex: 5;
}
.stepper_content {
  position: relative;
}
.stepper_content .title {
  position: relative;
  color: #000000;
  font-weight: 500;
  font-size: 14px;
}
.stepper_content .timestamp {
  position: absolute;
  color: #888888;
  font-size: 12px;
}
.summary_footer {
  display: flex;
  flex: 0;
  justify-content: space-between;
  margin-bottom: 10px;
}
.summary_footer .summary_archive_button {
  background: none !important;
  color: #008237 !important;
  border: 1px solid #008237 !important;
  width: 90% !important;
  height: 48px !important;
  border-radius: 12px !important;
  margin-right: 10px !important;
  font-weight: bold !important;
  font-size: 16px !important;
}
.summary_footer .summary_closed_button {
  background: #e72d35 !important;
  color: #fff !important;
  height: 48px !important;
  width: 90% !important;
  border-radius: 12px !important;
  font-size: 16px !important;
}
.summary_detail .summary_detail_content {
  margin-bottom: 25px;
}
.summary_detail .summary_detail_content_title {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 3px;
}
.summary_detail .summary_detail_content_value {
  font-size: 16px;
}
.audit_container {
  padding: 15px 25px;
}
.audit_header_leftside {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
}
.audit_title {
  font-size: 18px;
  color: #008237;
  font-weight: bold;
  margin-left: 10px;
}
.audit_container .audit_list {
  margin-top: 35px;
  background-color: #fff;
  padding: 10px;
  border-radius: 12px;
}
.audit_btn {
  background-color: #008237 !important;
  color: #fff !important;
  font-weight: bold !important;
  border-radius: 12px !important;
}
.audit_popup {
  min-height: 150px;
  padding: 15px;
}
.audit_popup .title {
  margin-bottom: 15px;
  font-weight: 700;
}
.audit_popup .audit_detail_content {
  border: 1px solid #d8d8d8;
  border-radius: 12px;
}
.audit_detail_content .header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 15px 0px 15px;
}
.audit_dot {
  width: 3px;
  height: 3px;
  background-color: black;
  border-radius: 50%;
  margin: 0px 8px;
}
.audit_name {
  font-weight: bold;
}
.audit_timestamp {
  color: #6f6f6f;
  font-weight: bold;
  font-size: 12px;
  padding-left: 15px;
  padding-bottom: 15px;
}
.audit_description {
  padding: 15px;
}
.description_title {
  font-weight: bold;
  margin-bottom: 10px;
}
.description_name {
  margin-bottom: 5px;
  font-size: 13px;
}
.description_name span {
  color: #008237;
}
@media only screen and (max-width: 768px) {
  .detail_form .form .form_container.remove_width {
    width: 100%;
  } 
.case_detail {
  width: 100%;
}
.__incident_mobile_form_tabs {
  height: auto;
  overflow-x: scroll;
  overflow-y: hidden;
}
.__incident_mobile_form_tabs {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.__incident_mobile_form_tabs::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
}